import React from 'react'
import styles from './NoItemsFound.module.sass'

function NoItemsFound() {
    return (
        <>
            <div className={styles['not-found']}>
                <p className={styles['message']}>😞 We are sorry but we can't get any data for you.</p>
            </div>
        </>
    )
}

export default NoItemsFound