import React from 'react'
import { motion } from 'framer-motion'
import useCategoryList from '../../hooks/fetchCategories'

import styles from './CategorySidebar.module.sass'

function CategorySidebar({ filterCategory, selectedCategory }) {
    const categories = useCategoryList()

    return (
        <>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className={styles['categories--list']}>
                <h2 className={styles['list--title']}>Filter by category:</h2>
                <ul className={styles['list--options']}>
                    <li className={`${styles['list--item']} ${selectedCategory === 'All' ? styles['active'] : ''}`} onClick={() => filterCategory('All')}>All</li>
                    {categories.length > 0 ? (
                        Object.values(categories).map((item, i) => (
                            <li key={i} className={`${styles['list--item']} ${item.id === selectedCategory ? styles['active'] : ''}`} onClick={() => filterCategory(item.id)}>{item.category}</li>
                        ))
                    ) : ''}
                </ul>
            </motion.div>
        </>
    )
}

export default CategorySidebar