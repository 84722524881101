import { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import axiosPrivate from '../../api/axios'
import Notification from '../Notification/Notification'
import useReactionList from '../../hooks/fetchReactions'
import appConfig from '../../app.config.json'

import styles from './SocialReactions.module.sass'

function SocialReactions({ factId }) {
    const reactions = useReactionList()
    const [activateEmojis, setActivateEmojis] = useState(false)
    const [buttonState, setButtonState] = useState({ color: null, message: null })

    const emojis = [
        { icon: '👍', label: 'likes' },
        { icon: '👎', label: 'dislikes' },
        { icon: '❤️', label: 'hearts' },
        { icon: '🥰', label: 'adorations' },
        { icon: '💯', label: 'grades' }
    ]

    const enableEmojis = () => setActivateEmojis(!activateEmojis)

    const setReaction = async (e) => {
        const emoji = e.target.textContent

        await axiosPrivate.post(appConfig.ENDPOINTS.REACTIONS_ROUTE, { factId: factId, emoji: emoji })
            .then((response) => {
                setButtonState({ color: 'green', message: response.data.message })
            })
            .catch((error) => {
                console.error(error)
                setButtonState({ color: 'red', message: error.response.data.message })
            })
    }

    return (
        <>
            <AnimatePresence>
                <ul className={styles['reactions--container']}>
                    {activateEmojis && Object.values(emojis).map((item, i) => (
                        <motion.li
                            layout
                            initial={{ width: 0 }}
                            animate={{ width: 20 }}
                            exit={{ width: 0 }}
                            key={i}
                            onClick={(e) => {
                                setReaction(e)
                                enableEmojis()
                            }}
                            className={styles['reaction']}
                        >
                            {item.icon}
                        </motion.li>
                    ))}

                    {!activateEmojis && Object.values(emojis).map((item) =>
                        Object.values(reactions).map((itemAPI) => {
                            const emojiAmount = itemAPI[item.label]

                            if (factId === itemAPI.fact_id && emojiAmount) {
                                return (
                                    <motion.li
                                        layout
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        key={itemAPI.id}
                                        onClick={() => enableEmojis()}
                                        className={`${styles['reaction']} ${styles['reaction--count']}`}
                                    >
                                        <span className={styles['icon']}>{item.icon}</span>
                                        <span className={styles['amount']}>{emojiAmount}</span>
                                    </motion.li>
                                )
                            }

                            return null
                        })
                    )}

                    {!activateEmojis ? (
                        <li onClick={() => enableEmojis()} className={styles['reaction']}>➕</li>
                    ) : (
                        <li onClick={() => enableEmojis()} className={styles['reaction']}>❌</li>
                    )}
                </ul>

                <Notification buttonState={buttonState} setButtonState={setButtonState} />
            </AnimatePresence >
        </>
    )
}

export default SocialReactions