import { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import useFactsList from './hooks/fetchFacts'

import Header from './components/Header/Header'
import CategorySidebar from './components/CategorySidebar/CategorySidebar'
import FactCard from './components/FactCard/FactCard'
import NoItemsFound from './components/NoItemsFound/NoItemsFound'

import styles from './App.module.sass'
import cardStyles from './components/FactCard/FactCard.module.sass'

function App() {
	const facts = useFactsList()
	const [selectedCategory, setSelectedCategory] = useState('All')
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		setTimeout(() => { setLoading(false) }, 1000)
	}, [])

	const filteredFacts = Object.values(facts).filter((item) => selectedCategory === 'All' || item.category_id === selectedCategory)

	const filterCategory = (category) => {
		setSelectedCategory(category)
	}

	return (
		<div className={`${styles['page--size']} ${styles['page--padding']}`}>
			<Header />

			<AnimatePresence>
				<div className={styles['facts--container']}>
					{filteredFacts.length === 0 && !loading ? <NoItemsFound /> :
						loading ? (
							<p>⏳ Loading...</p>
						) : (
							<>
								<CategorySidebar filterCategory={filterCategory} selectedCategory={selectedCategory} />

								<motion.div className={cardStyles['flex--cards']}>
									<AnimatePresence>
										{filteredFacts.map((item, i) => (
											<FactCard key={item.id} factData={item} />
										))}
									</AnimatePresence>
								</motion.div>
							</>
						)
					}
				</div>
			</AnimatePresence>
		</div>
	)
}

export default App