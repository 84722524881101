import { useState, useEffect } from 'react'
import axiosPrivate from '../api/axios'
import appConfig from '../app.config.json'

const useFactsList = () => {
    const [facts, setFacts] = useState({})

    useEffect(() => {
        const fetchFacts = async () => {
            try {
                const response = await axiosPrivate.get(appConfig.ENDPOINTS.FACTS_ROUTE)
                setFacts(response.data.reverse())
            } catch (error) {
                if (error.response && error.response.status === 500)
                    console.error(error)
            }
        }

        fetchFacts()
    }, [facts])

    return facts
}

export default useFactsList