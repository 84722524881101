export const formatDate = (date) => {
    if (!date) return null;
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = new Date(date).toLocaleDateString('en-GB', options);
    return formattedDate.replace(/\//g, '.');
}

export const formatTime = (time) => {
    if (!time) return null;
    const options = { hour: '2-digit', minute: '2-digit' };
    return new Date(time).toLocaleTimeString('en-GB', options);
}