import { useState } from 'react'
import axiosPrivate from '../../api/axios'
import appConfig from '../../app.config.json'

import Notification from '../Notification/Notification'
import CategoryDropdown from '../CategoryDropdown/CategoryDropdown'

import styles from './NewFactForm.module.sass'
import appStyles from '../../App.module.sass'

function NewFactForm() {
    const MAX_LENGTH = 500

    const [text, setText] = useState('')
    const [source, setSource] = useState('')
    const [category, setCategory] = useState('')
    const [buttonState, setButtonState] = useState({ color: null, message: null })

    const sendFactsData = async (e) => {
        e.preventDefault()

        if (text === '') return setButtonState({ color: 'red', message: 'The share a fact input should not be empty.' })
        if (source === '') return setButtonState({ color: 'red', message: 'The source input should not be empty.' })

        await axiosPrivate.post(appConfig.ENDPOINTS.FACTS_ROUTE, { message: text, source: source, category: category })
        .then((response) => {
            setText('')
            setSource('')
            setCategory('')
            setButtonState({ color: 'green', message: response.data.message })
        })
        .catch((error) => {
            console.error(error)
            setButtonState({ color: 'red', message: error.response.data.message })
        })
    }

    const updateFactInput = (e) => {
        const inputValue = e.target.value

        if (inputValue.length <= MAX_LENGTH) setText(inputValue)
        else return
    }

    const updateSourceInput = (e) => {
        const inputValue = e.target.value
        setSource(inputValue)
    }

    const updateCategoryInput = (selectedCategory) => {
        setCategory(selectedCategory)
    }

    return (
        <>
            <form className={styles['fact--form']} onSubmit={sendFactsData}>
                <div className={styles['fact--form-input']}>
                    <input className={styles['fact--input']} type='text' onChange={updateFactInput} value={text} placeholder='Share a fact with the world...' />
                    <span className={styles['fact--form-length']}>{MAX_LENGTH - text.length}</span>
                </div>
                <div className={styles['fact--form-input']}>
                    <input type='text' onChange={updateSourceInput} value={source} placeholder='Trustworthy source...' />
                </div>
                <div className={styles['fact--form-input']}>
                    <CategoryDropdown onCategoryChange={updateCategoryInput} categoryInput={category} />
                </div>
                <button className={appStyles['primary--btn']}>Post</button>
            </form>

            <Notification buttonState={buttonState} setButtonState={setButtonState} />
        </>
    )
}

export default NewFactForm