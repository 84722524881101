import axios from 'axios'
import appConfig from '../app.config.json'

const axiosPrivate = axios.create({
    baseURL: appConfig.SERVER,
    timeout: 2000,
    headers: {
        'Authorization': 'Basic ' + btoa(`${appConfig.AUTHORIZATION.USER}:${appConfig.AUTHORIZATION.PASSWORD}`),
        'Content-Type': 'application/json'
    }
})

export default axiosPrivate