import { motion } from 'framer-motion'
import { formatDate, formatTime } from '../../utils/dateUtils'

import useCategoryList from '../../hooks/fetchCategories'
import SocialReactions from '../SocialReactions/SocialReactions'

import styles from './FactCard.module.sass'

function FactCard({ factData }) {
    const categories = useCategoryList()

    const getHostname = (completeURL) => {
        try {
            const url = new URL(completeURL)
            let hostname = url.hostname
    
            if (hostname.startsWith('www.'))
                hostname = hostname.substring(4)
    
            return hostname
        } catch (error) {
            return completeURL
        }
    }

    const formattedDate = formatDate(factData.post_date)
    const formattedTime = formatTime(factData.post_date)

    return (
        <>
            <motion.div layout initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ delay: .3, duration: .3 }} className={styles['fact--card']}>
                <div className={styles['message']}>{factData.fact}</div>
                <div className={styles['card--footer']}>
                    <a href={factData.source} className={styles['source-link']} target='_blank' rel='noreferrer'>Source: {getHostname(factData.source)}</a>
                    <div className={styles['date']}>Added on: {`${formattedDate} - ${formattedTime}`}</div>
                    <div className={styles['source-link']}>Category: {categories.find((category) => category.id === factData.category_id)?.category}</div>
                </div>

                <SocialReactions factId={factData.id} />
            </motion.div>
        </>
    )
}

export default FactCard