import React, { useState } from 'react'
import useCategoryList from '../../hooks/fetchCategories'

import styles from './CategoryDropdown.module.sass'

function CategoryDropdown({ onCategoryChange, categoryInput }) {
    const [open, setOpen] = useState(false)
    const categories = useCategoryList()

    const openDropdown = () => setOpen(!open)

    const getCategory = (e) => {
        setOpen(!open)
        if (onCategoryChange) onCategoryChange(e.target.textContent)
    }

    const updateCategoryInput = (e) => {
        if (onCategoryChange) onCategoryChange(e.target.value)
    }

    return (
        <>
            <input className={styles['dropdown--input']} onChange={updateCategoryInput} value={categoryInput} type='text' placeholder='Select a category...' />
            <div className={styles['dropdown--trigger']} onClick={openDropdown}>
                <span className='material-symbols-outlined'>expand_more</span>
            </div>

            <div className={`${styles['dropdown--list']} ${open ? styles['visible'] : ''}`}>
                <ul className={styles['list--options']}>
                    {categories.length > 0 ? (
                        Object.values(categories).map((item, i) => (
                            <li key={i} className={styles['list--item']} onClick={getCategory}>{item.category}</li>
                        ))
                    ) : ( <li className={styles['list--item_not-found']}>😞 No categories found.</li> )}
                </ul>
            </div>
        </>
    )
}

export default CategoryDropdown