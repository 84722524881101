import { useState, useEffect } from 'react'
import axiosPrivate from '../api/axios'
import appConfig from '../app.config.json'

const useCategoryList = () => {
    const [categories, setCategories] = useState([])

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axiosPrivate.get(appConfig.ENDPOINTS.CATEGORIES_ROUTE)
                setCategories(response.data.reverse())
            } catch (error) {
                if (error.response && error.response.status === 500)
                    console.error(error)
            }
        }

        fetchCategories()
    }, [categories])

    return categories
}

export default useCategoryList