import { useState, useEffect } from 'react'
import axiosPrivate from '../api/axios'
import appConfig from '../app.config.json'

const useReactionList = () => {
    const [reactions, setReactions] = useState([])

    useEffect(() => {
        const fetchReactions = async () => {
            try {
                const response = await axiosPrivate.get(appConfig.ENDPOINTS.REACTIONS_ROUTE)
                setReactions(response.data.reverse())
            } catch (error) {
                if (error.response && error.response.status === 500)
                    console.error(error)
            }
        }

        fetchReactions()
    }, [reactions])

    return reactions
}

export default useReactionList