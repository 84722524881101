import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import NewFactForm from '../NewFactForm/NewFactForm'

import styles from './Header.module.sass'
import appStyles from '../../App.module.sass'

function Header() {
    const [showForm, setShowForm] = useState(false)
    const showFactForm = () => setShowForm(!showForm)

    return (
        <>
            <div className={styles.header}>
                <div className={styles.logo}>
                    <img className={styles['logo--image']} src='/assets/logo.png' alt='Facts List' />
                    <h1 className={styles['logo--title']}>Facts List Wrote By The People</h1>
                </div>
                <button className={appStyles['primary--btn']} onClick={showFactForm}>{showForm ? 'Close' : 'Share a Fact'}</button>
            </div>

            <AnimatePresence>
                {showForm && (
                    <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                        className={styles['factForm']}
                    >
                        <NewFactForm />
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    )
}

export default Header