import React, { useState, useEffect } from 'react'
import styles from './Notification.module.sass'

function Notification({ buttonState, setButtonState }) {
    const [colorClass, setColorClass] = useState('')
    const [isActive, setIsActive] = useState(false)
    const [notificationTimer, setNotificationTimer] = useState(5000)

    const closeNotification = () => setIsActive(!isActive)

    useEffect(() => {
        setNotificationTimer(5000)
        if(buttonState.message !== null) setIsActive(true)
    }, [buttonState])

    useEffect(() => {
        const setBoxColor = () => {
            switch (buttonState.color) {
                case 'red': setColorClass('red'); break
                case 'green': setColorClass('green'); break
                default: setColorClass('light-brown'); break
            }
        }

        setBoxColor(buttonState)

        if (isActive) {
            if (notificationTimer > 0) {
                const timerId = setTimeout(() => setNotificationTimer(notificationTimer - 1000), 1000)
                return () => clearTimeout(timerId)
            } else {
                setIsActive(false)
                setNotificationTimer(5000)
            }
        }

    }, [buttonState,
        setButtonState,
        isActive,
        notificationTimer])

return (
    <>
        <div className={`${styles['notification--box']} ${styles[colorClass]} ${isActive ? styles['visible'] : ''}`}>
            {buttonState.message}
            <span className={`material-symbols-outlined ${styles['close--button']}`} onClick={closeNotification}>close</span>
        </div>
    </>
)
}

export default Notification